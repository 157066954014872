/**
 * Solutions page
 */
/* Import section */
import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next"
import Blockfeaturesvertical from "../components/blockfeaturesvertical"
import Checklistproject from "../components/checklistproject"
//import Ctabutton from "../components/ctabutton"
import Herotype2 from "../components/herotype2"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/pages/solutions.scss"

/* Declaration */
const SolutionsPage = ({ data }) => {
  //Const data
  const dataPage = data.wpPage
  //const {t} = useTranslation()
  const context = React.useContext(I18nextContext)
  var cLangSlug = context.language
  var cLang = cLangSlug === "en" ? "En" : "Fr"

  //Variables of the query
  var cAcf = "acfPageSolutions" + cLang
  var cTitleH1 = "titleH1Solutions" + cLang
  var cTitleIntro = "titleIntroSolutions" + cLang
  var cTextIntro = "textIntroSolutions" + cLang
  var cRepeaterFeatures = "repeaterFeatures" + cLang
  var cTitleInternationalSolutions = "titleInternationalSolutions" + cLang
  var cRepeaterProjects = "repeaterProjects" + cLang

  const dataAcf = dataPage[cAcf]
  const titleH1 = dataAcf[cTitleH1]
  const titleIntro = dataAcf[cTitleIntro]
  const textIntro = dataAcf[cTextIntro]
  const repeaterFeatures = dataAcf[cRepeaterFeatures]
  const titleInternationalProjects = dataAcf[cTitleInternationalSolutions]
  const repeaterProjects = dataAcf[cRepeaterProjects]

  //Array for features vertical
  let arrFeatures = []
  repeaterFeatures.map((feature, index) =>
    arrFeatures.push({
      idFeature: "feature-" + index,
      indexFeature: index,
      titleFeature: feature.titleFeature,
      descFeature: feature.textFeature,
      visualFeature:
        feature.imageFeature.localFile.childImageSharp.gatsbyImageData,
    })
  )
  var classImg = ""

  return (
    <Layout>
      <Seo title={dataPage.seo.title} description={dataPage.seo.metaDesc} />

      <section className="section-hero-solutions section-herotype2">
        <Herotype2 h1title={titleH1} classImg="solutions" />
      </section>

      <section className="section-intro">
        <div className="wrap-centercontent">
          <div className="content-intro centercontent">
            <h2>{titleIntro}</h2>
            <div
              className="text-intro"
              dangerouslySetInnerHTML={{ __html: textIntro }}
            />
          </div>
        </div>
      </section>

      <section className="section-features">
        <div className="wrap-verticalfeatures">
          <Blockfeaturesvertical
            arrFeaturesVertical={arrFeatures}
            classImg={classImg}
          />
        </div>
      </section>

      <section className="section-projects">
        <div className="wrap-projects">
          <h2>{titleInternationalProjects}</h2>

          {repeaterProjects.map((project, index) => (
            <div key={index} className="box-project">
              <div className="blockimg-project">
                <GatsbyImage
                  image={
                    project.imageProject.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt={`Myca: ${project.titleProject}`}
                />
              </div>

              <div className="block-project">
                <div className="content-project">
                  <h3>{project.titleProject}</h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: project.textProject }}
                  />

                  {project.subtitleListProject !== null && (
                    <div>
                      <p className="subtitle">{project.subtitleListProject}</p>
                      <Checklistproject
                        repeaterChecklist={project.repeaterListProject}
                        colorCheck="bluelight"
                      />
                    </div>
                  )}

                  {/*index === 1 && <Ctabutton classCta="cta-project" ctaLabel={t('cta-australia')} ctaLink="https://hellohealthemr.com.au" extLink="extLink"  />*/}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    wpPage(
      slug: { regex: "/solutions/" }
      language: { slug: { eq: $language } }
    ) {
      acfPageSolutionsEn {
        titleH1SolutionsEn
        titleIntroSolutionsEn
        textIntroSolutionsEn
        repeaterFeaturesEn {
          titleFeature
          textFeature
          imageFeature {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  formats: [AUTO, WEBP]
                  width: 800
                )
              }
            }
          }
        }
        titleInternationalSolutionsEn
        repeaterProjectsEn {
          titleProject
          textProject
          imageProject {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  formats: [AUTO, WEBP]
                  width: 1200
                )
              }
            }
          }
          subtitleListProject
          repeaterListProject {
            itemListProject
          }
        }
      }
      acfPageSolutionsFr {
        titleH1SolutionsFr
        titleIntroSolutionsFr
        textIntroSolutionsFr
        repeaterFeaturesFr {
          titleFeature
          textFeature
          imageFeature {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  formats: [AUTO, WEBP]
                  width: 800
                )
              }
            }
          }
        }
        titleInternationalSolutionsFr
        repeaterProjectsFr {
          titleProject
          textProject
          imageProject {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  formats: [AUTO, WEBP]
                  width: 1200
                )
              }
            }
          }
          subtitleListProject
          repeaterListProject {
            itemListProject
          }
        }
      }
      seo {
        metaDesc
        title
      }
    }
  }
`

export default SolutionsPage

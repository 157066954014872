/**
 * Class element
 * Block features vertical component
 * Display a block of features vertically with visual (change with scroll)
 */
/* Import section */
import { GatsbyImage } from "gatsby-plugin-image"
import React, { Component } from "react"
import Animateverticalimg from "../components/animateverticalimg"
import "../styles/components/blockfeaturesvertical.scss"


/* Declaration class */
class Blockfeaturesvertical extends Component {

  constructor(props) {

    super(props)
    this.arrFeatures = this.props.arrFeaturesVertical

    this.state = {
      indexActive: 0,
      titleImg: this.arrFeatures[0].titleFeature,
      visualImg: this.arrFeatures[0].visualFeature,
      width: 0,
    }
  }

  componentDidMount() {
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
    this.handleWindowSizeChange()
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    if(window.innerWidth !== this.state.width) {
      this.setState({
        indexActive: this.state.indexActive,
        width: window.innerWidth,
      })
    }
  }

  handleScroll = () => {

    var activeIndex = this.state.indexActive
    let arrItemVerticalOffsetTop = []
    var itemVisualVertical = [].slice.call((document.getElementsByClassName("visual-feature-vertical")))
    if(itemVisualVertical.length > 0) {
      var offSetTopitemVisualVertical = itemVisualVertical[0].offsetTop + 100
    }

    var itemVertical = [].slice.call((document.getElementsByClassName("feature-vertical")))
    if(itemVertical.length > 0) {
      itemVertical.map((feature, index) => {
        return arrItemVerticalOffsetTop.push([index, feature.offsetTop - 200, feature.offsetTop+feature.offsetHeight])
      })
    }

    var arrItemVerticalFilter = arrItemVerticalOffsetTop.filter(function(element){
      return element[1] < offSetTopitemVisualVertical && offSetTopitemVisualVertical < element[2]
    })

    if(Array.isArray(arrItemVerticalFilter) && arrItemVerticalFilter.length) {
      activeIndex = arrItemVerticalFilter[0][0]
    }

    var currentVerticalFeature = [].slice.call((document.getElementsByClassName("feature-vertical active")))
    if(currentVerticalFeature.length > 0) {
      currentVerticalFeature[0].classList.remove("active")
    }

    var activeVerticalFeature = document.getElementById(`feature-${activeIndex}`)
    activeVerticalFeature.classList.add("active")

    var itemOfVisual = this.arrFeatures.filter(item => item.indexFeature === activeIndex)

    this.setState({
      indexActive: activeIndex,
      titleImg: itemOfVisual[0].titleFeature,
      visualImg: itemOfVisual[0].visualFeature,
    })
  }

  render () {
    const isTablet = this.state.width <= 834

    return (
      <div className="wrap-maincontent wrap-features-vertical">
        {!isTablet && (
           <div className={`visual-feature-vertical ${this.props.classImg}`}>
             <Animateverticalimg key={this.state.indexActive} visualFeature={this.state.visualImg} titleFeature={this.state.titleImg} />
          </div>
        )}
        <div className="block-features-vertical">
          {this.arrFeatures.map( (feature )  => (
            <div id={feature.idFeature}  className="feature-vertical">
              {isTablet && (
                <div className={`visual-feature-vertical-tablet ${this.props.classImg}`}>
                  <GatsbyImage image={feature.visualFeature} alt={`Myca: ${feature.titleFeature}`} />
                </div>
              )}
              <h3>{feature.titleFeature}</h3>
              <div dangerouslySetInnerHTML={{ __html:feature.descFeature}}/>
            </div>
          ))}
        </div>
      </div>
    )
  }
}


/* Export function */
export default Blockfeaturesvertical


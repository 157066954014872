/**
 * Const with params element
 * Animate VErtical images component
 * Display an animation of icons with Framer API Motion
 */
/* Import section */

import { motion, AnimatePresence } from "framer-motion"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const ANIMATION_DURATION_S = 0.5

/* Declaration function */
const Animateverticalimg = ( { key, visualFeature,  titleFeature} ) => {
  return (
    <AnimatePresence>
      <motion.div
        key={key}
        className="animate-images-vertical"
        initial={{ scale: 0.1, opacity: 0 }}
        exit={{ opacity: 0 }}
        animate={{ scale:1, opacity: 1 }}
        transition={{ duration: ANIMATION_DURATION_S, ease: "easeInOut" }}
      >
      <GatsbyImage key={key} image={visualFeature} alt={`Hello Health's visual ${titleFeature}`} />
    </motion.div>
  </AnimatePresence>
  )
}

/* Export function */
export default Animateverticalimg
